.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-itm.mbsc-sc-itm-sel {
  color: #F2A40B
}

.mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-itm {
  font-size: 16px;
  padding: 0 1.1em;
  font-weight: bold;
  letter-spacing: 1.6px;
}

.mbsc-mobiscroll .mbsc-sc-whl-l {
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
}

.mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-btn:hover:before,
.mbsc-mobiscroll.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
.mbsc-mobiscroll .mbsc-sc-btn.mbsc-active:before {
  background: rgba(255, 85, 0, 0.3)
}

.mbsc-mobiscroll.mbsc-input {
  margin: 0;
}

.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-input-wrap {
  background: none;
}

.mbsc-mobiscroll.mbsc-input-box.mbsc-input input,
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-color-input {
  padding: 0 0 0 16px;
  height: auto;
  color: #272727;
  font-size: 14px;
  line-height: 32px;
  border: 1px solid #ccc;
  border-radius: 16px;
  background-image: url('../images/select.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-size: 8px;
  appearance: none;
}
.mbsc-mobiscroll.mbsc-input-box.mbsc-input input::placeholder,
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-color-input::placeholder {
  color: #272727;
}

.mbsc-mobiscroll.mbsc-input-box.mbsc-input input.big,
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-color-input.big {
  padding: 0 0 0 20px;
  font-size: 16px;
  line-height: 42px;
  border-radius: 21px;
  background-position: right 20px center;
}

.mbsc-mobiscroll.mbsc-input-box.mbsc-input input.error,
.mbsc-mobiscroll.mbsc-input-box.mbsc-input .mbsc-color-input.error {
  border: 1px solid #F2A40B;
}

.mbsc-mobiscroll.mbsc-input .mbsc-control:focus,
.mbsc-mobiscroll.mbsc-input select:focus~input .mbsc-input textarea:focus {
  border-color: #ccc;
}