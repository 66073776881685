.popup-cascader-customize .ant-cascader-menu {
  height: 300px;
}

.popup-cascader-customize {
  width: 11.5%;
}

.popup-cascader-customize .ant-cascader-menu {
  width: 100%;
}

.popup-cascader-customize .ant-cascader-menu:last-child {
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.popup-cascader-customize .ant-cascader-menu .ant-cascader-menu-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.popup-cascader-customize .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-disabled {
  display: none;
}

.popup-cascader-customize-date .ant-cascader-menu .ant-cascader-menu-item {
  font-weight: 500;
  text-align: center;
  color: #272727;
}
.popup-cascader-customize-date .ant-cascader-menu .ant-cascader-menu-item:hover {
  background-color: #f2f2f2;
}
.popup-cascader-customize-date .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  color: #f2a40b;
  font-weight: bold;
}
.popup-cascader-customize-date .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active .anticon.anticon-right {
  color: #f2a40b;
}
.date-change-container--cascader-active .SingleDatePicker .DateInput_input {
  color: #ccc;
}

.ant-select-dropdown-menu {
  overscroll-behavior: contain;
}
