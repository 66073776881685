.ant-select {
  width: 100%;
}

.ant-select-selection {
  transition: unset !important;
  border-radius: 16px;
  border: 1px solid #cccccc;
  box-shadow: none !important;
  border-color: #cccccc !important;
}

.ant-select-selection__choice {
  background-color: #f2f2f2 !important;
  border-radius: 12px !important;
  border: none !important;
}
.ant-select-dropdown-menu-item-selected .ant-select-selected-icon {
  color: #f2a40b !important;
}
.ant-select-selection__placeholder {
  padding-left: 5px;
}
.ant-select-selection-selected-value {
  color: #272727 !important;
  max-width: calc(100% - 14px);
}

.ant-select-selection__rendered {
  padding-left: 5px !important;
  margin-right: 0px !important;
}

.ant-select-arrow {
  margin-top: -3px !important;
  right: 7px;
}
.ant-select-arrow-icon {
  width: 10px;
}

.ant-select-tree-node-content-wrapper {
  overflow-wrap: anywhere;
  white-space: break-spaces;
}
