.CalendarMonth_table tr {
    border-bottom: none !important;
}
.CalendarMonth_table tr:hover {
    background-color: transparent !important;
}
/* 日付セル */
.CalendarDay {
    font-size: 12px;
    z-index: 1;
    position: relative;
    vertical-align: middle;
    overflow: visible !important;
}
.CalendarDay span {
    display: block;
    letter-spacing: 0;
}
.CalendarDay__default,
.CalendarDay__default:hover {
    border: none;
    padding: 0 !important;
    background: none;
}
/* 確定済みの日 */
.CalendarDay__highlighted_calendar div {
  color: #A3A3A3;
}
/* 今日 */
.CalendarDay__today div {
    border-radius: 50%;
    background: #F2F2F2;
    border: none;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    padding-top: 8px;
}
/* 日付セルホバーのスタイル */
.CalendarDay__default:hover div {
    border-radius: 50%;
    color: #fff;
    background: #F2A40B;
    border: none;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    padding-top: 8px;
}
/* デフォルトスタイルの無効化 */
.CalendarDay__selected,
.CalendarDay__selected_span,
.CalendarDay__selected_span:hover,
.CalendarDay__selected:active,
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: none;
    border: none;
    position: relative;
}
.CalendarDay__selected_span:active {
    background: transparent;
    border: none;
}
/* 開始点との結合部 */
.CalendarDay__selected_span span:before,
.CalendarDay__hovered_span span:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 11px solid #FFFDE3;
    border-bottom: 10px solid #FFFDE3;
    border-left: 10px solid #FFFDE3;
    border-right: 3px solid #FFFDE3;
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: -2;
}
/* 週初めは結合部のデザインを隠す */
.CalendarDay__firstDayOfWeek span:before {
    border: none;
}
.CalendarDay__hovered_span span:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 11px solid #FFFDE3;
    border-bottom: 10px solid #FFFDE3;
    border-left: 5px solid #FFFDE3;
    border-right: 5px solid #FFFDE3;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: -1;
}
/* レンジ間の日付セルホバー時　*/
.CalendarDay__selected_span:hover div {
    background: none;
    padding: 0;
    width: 100%;
    height: auto;
}
.CalendarDay__selected_span:hover span {
    display: block;
}
/* 選択済み開始点と終了点のスタイル */
.CalendarDay__selected_start {
    position: relative;
    z-index: 10;
}
.CalendarDay__selected_end {
    position: relative;
}
/* 終了点が開始点の次の日の場合 */
.CalendarDay__selected_start + .CalendarDay__selected_end span:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 11px solid #FFFDE3;
    border-bottom: 10px solid #FFFDE3;
    border-left: 5px solid #FFFDE3;
    border-right: 5px solid #FFFDE3;
    position: absolute;
    top: 10px;
    left: -10px;
    z-index: -1;
}
/* 終了点と選択日付感の結合部 */
.CalendarDay__selected_end span:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 11px solid #FFFDE3;
    border-bottom: 10px solid #FFFDE3;
    border-left: 5px solid #FFFDE3;
    border-right: 5px solid #FFFDE3;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: -1;
}
.CalendarDay__selected_start.CalendarDay__selected_end span:after {
    border: none;
}
/* 開始点と終了点のデザイン */
.CalendarDay__selected_start div,
.CalendarDay__selected_end div,
.CalendarDay__selected div {
    border-radius: 50%;
    color: #fff;
    background: #F2A40B;
    border: none;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    padding-top: 8px;
}

/* カレンダー全体 */
.DayPicker__withBorder {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.DayPickerNavigation_button__default {
    border: none;
}
.DateRangePickerInput__withBorder {
    border-radius: 16px;
    padding: 0 6px 0 16px;
    height: 32px;
}
.DateInput {
    background: none;
    width: 130px;
}
.DateInput_input__focused {
    border-bottom: none;
}
.DateInput_input {
    border-bottom: none;
    background-color: transparent;
    font-size: 14px;
    padding: 8px 0;
    line-height: 16px;
    letter-spacing: 1px;
}
.DateInput_input::placeholder {
    color: #cccccc;
    font-size: 14px
}
input[name="endDate"] {
    pointer-events : none;
}
.CalendarMonth_caption {
    color: #272727;
    font-size: 16px;
    padding-bottom: 42px;
    padding-top: 7px;
}
.CalendarMonth_caption strong {
    letter-spacing: 0.05em;
}
.DateRangePicker_picker {
    margin-top: -29px;
    border-radius: 5px;
}
.DateInput_fang {
    display: none;
}
.DateRangePickerInput_arrow {
    color: #cccccc;
}
.DateInput_input {
    color: #272727;
    text-align: center;
}
.DateInput_input::placeholder,
.DateInput_input:-ms-input-placeholder,
.DateInput_input::-ms-input-placeholder {
    color: #cccccc;
}
/* カレンダー曜日 */
.DayPicker_weekHeader {
    top: 58px;
}
.DayPicker_weekHeader_ul {
    font-size: 12px;
    color: #676767;
    font-weight: bold;
}
.DayPicker_weekHeader_ul li {
    font-size: 11px;
}
.DayPicker_weekHeader_ul li small {
    letter-spacing: 0;
}
/* 選択またはホバー状態の選択日付間デザイン */
.CalendarDay__selected_span span,
.CalendarDay__hovered_span span {
    background: #FFFDE3;
    color: #000;
    border: none;
    display: block;
    padding: 4px 0;
    height: 21px;
}
/*　本日のセルが選択またはホバー状態にある場合にデザインを無効化 */
.CalendarDay__today.CalendarDay__hovered_span div,
.CalendarDay__today.CalendarDay__selected_span div {
    background: #F2F2F2;
    border: none;
    display: block;
    width: auto;
    height: auto;
    margin: 0 auto;
    padding-top: 0;
}
/* ホバー時デザイン　*/
.CalendarDay__hovered_span:hover div {
    width: 30px;
    height: 30px;
    background: #F2A40B;
    border: none;
    margin: 0 auto;
    padding-top: 8px;
    border-radius: 50%;
    z-index: 10;
}
/* 現在選択されているセルは、ホバー時のデザインを無効化 */
.CalendarDay:hover.CalendarDay__hovered_span:hover span {
    background: transparent;
    color: #fff;
    padding: 0;
}
.DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 3px 0 0 0;
}
.monthPrev {
    left: 37px;
    color: #597C89;
    font-size: 24px;
    padding: 0;
    position: absolute;
    top: 15px;
  }
  .monthNext {
    right: 39px;
    color: #597C89;
    font-size: 24px;
    padding: 0;
    position: absolute;
    top: 15px;
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
    color: #597C89;
    font-size: 24px;
    padding: 0;
    position: absolute;
    top: 15px;
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    color: #597C89;
    font-size: 24px;
    padding: 0;
    position: absolute;
    top: 15px;
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden
  }
  img[alt="arrow"] {
    width: 30px;
    margin-left: -8px;
    margin-right: -11px;
  }
  img[alt="calendar"] {
    margin-top: 2px;
  }
  #startDate {
    padding-left: 12.65px;
    margin: 0;
  }
  #endDate {
    padding-left: 3px;
    margin: 0;
  }
  .DateInput_1, .DateRangePickerInput_arrow_1 {
    margin-top: -1px;
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }

  .SingleDatePicker_picker__directionLeft {
    left: 0;
    top: -95px !important;
  }