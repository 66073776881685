.ant-select-dropdown-menu-item-selected {
  color: #f2a40b !important;
}
.ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown-menu-item.hidden {
  display: none;
}
.ant-select-dropdown-menu-item {
  .ant-tag {
    background: none !important;
    font-size: 16px;
    color: #272727 !important;
  }
}
.ant-select-dropdown-menu {
  @media (max-width: 1080px) {
    max-height: 125px;
  }
}